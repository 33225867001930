import { useHistory } from "react-router-dom";
import { Button, Descriptions } from "antd";

import {
  AdminBidDetail,
  BidQuotation,
} from "@sellernote/_shared/src/types/forwarding/adminBid";

import { numberFormat } from "services/util";

interface Props {
  quotations: BidQuotation[];
  status: string;
  customs: boolean;
  exchangeRateList: any;
  bidData: AdminBidDetail;
}

const QuotationDesc = (props: Props) => {
  const { quotations, status, customs, exchangeRateList, bidData } = props;

  const history = useHistory();

  return (
    <div>
      {quotations.length > 0 && (
        <Descriptions
          contentStyle={{ fontWeight: "bold" }}
          column={5}
          title={
            <div style={{ fontWeight: "bold", fontSize: 20 }}>견적서 초안</div>
          }
        >
          {quotations.map((v, index) => {
            return (
              <>
                <Descriptions.Item
                  label={
                    <div
                      style={{
                        color: "#1890FF",
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      ‣ 초안{index + 1}
                    </div>
                  }
                  span={5}
                >
                  {""}
                </Descriptions.Item>

                <Descriptions.Item label="제출가">
                  {bidData.locale === "KR"
                    ? `${numberFormat(v.totalPrice)}원`
                    : `SGD ${numberFormat(v.totalPrice)}`}
                </Descriptions.Item>

                <Descriptions.Item label="제출일자">
                  {new Date(v.createdAt).toLocaleDateString()}
                </Descriptions.Item>

                <Descriptions.Item label="유효기간">
                  {new Date(v.expiredAt).toLocaleDateString()}
                </Descriptions.Item>

                {bidData.quotationsUser ? (
                  <Descriptions.Item
                    contentStyle={{ marginTop: -1 }}
                    label={`초안${index + 1} 확인`}
                  >
                    <Button
                      style={{
                        width: 42,
                        height: 24,
                        padding: 0,
                        color: "#1890FF",
                      }}
                      onClick={() => {
                        history.push(
                          `/bid/change-quotation/${bidData.id}/${bidData.freightType}`
                        );
                      }}
                    >
                      확인
                    </Button>
                  </Descriptions.Item>
                ) : (
                  <Descriptions.Item
                    contentStyle={{ marginTop: -1 }}
                    label={`초안${index + 1} 수정`}
                  >
                    <Button
                      style={{
                        width: 42,
                        height: 24,
                        padding: 0,
                        color: "#1890FF",
                      }}
                      onClick={() => {
                        history.push(
                          `/bid/change-quotation/${bidData.id}/${bidData.freightType}`
                        );
                      }}
                    >
                      수정
                    </Button>
                  </Descriptions.Item>
                )}
                {bidData.quotationsUser.length === 0 && (
                  <Descriptions.Item
                    contentStyle={{ marginTop: -1 }}
                    label={`초안${index + 1} 선택`}
                  >
                    <Button
                      style={{
                        width: 42,
                        height: 24,
                        padding: 0,
                        color: "#1890FF",
                      }}
                      onClick={() => {
                        history.push(`/bid/confirm-quotation/${bidData.id}`);
                      }}
                    >
                      선택
                    </Button>
                  </Descriptions.Item>
                )}
              </>
            );
          })}
        </Descriptions>
      )}
      {quotations.length === 0 && bidData.status === "estimating" && (
        <Button
          style={{ color: "#1890FF" }}
          onClick={() => {
            history.push(`/bid/applyBid/${bidData.freightType}/${bidData.id}`);
          }}
        >
          견적 지원
        </Button>
      )}
    </div>
  );
};

export default QuotationDesc;
