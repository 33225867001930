import { useAtom } from "jotai";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_USER_QUERY";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import { FORWARDING_ADMIN_BID_JOTAI_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/bid";
import useAdminShipmentTable from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/hooks/useAdminShipmentTable";
import useShipmentTableHeadFilter from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/hooks/useShipmentTableHeadFilter";
import useShipmentTablePanel from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/hooks/useShipmentTablePanel";

// TODO: 의뢰 리스트의 page URL을 shipment로 할지 논의 후 수정
const Bid = () => {
  const [filterData, setFilterData] = useAtom(
    FORWARDING_ADMIN_BID_JOTAI_ATOMS.SHIPMENT_TABLE_FILTER_LIST
  );

  const {
    TablePanel,
    debouncedSearchTermWithObject,
    serviceType,
    status,
    projectStatus,
    dateSearchType,
    startDate,
    endDate,
    isEqualSearchTerm,
    isImport,
  } = useShipmentTablePanel();

  const { ShipmentTableHeadFilter, tableHeadFilterData } =
    useShipmentTableHeadFilter();

  const { data: bidListData } = ADMIN_BID_QUERY.useGetAdminBidList({
    params: {
      order: "DESC",
      locale: "KR",
      page: filterData.page,
      perPage: filterData.perPage,
      ...(dateSearchType && startDate && endDate
        ? {
            fromDate: startDate,
            toDate: endDate,
          }
        : {}),
      ...debouncedSearchTermWithObject,
      serviceType,
      status,
      projectStatus,
      isImport,
      ...tableHeadFilterData,
    },
    enabled: isEqualSearchTerm,
    onSuccess: () => {
      setFilterData({
        page: filterData.page,
        perPage: filterData.perPage,
        ...(dateSearchType && startDate && endDate
          ? {
              fromDate: startDate,
              toDate: endDate,
            }
          : {}),
        ...debouncedSearchTermWithObject,
        serviceType,
        status,
        projectStatus,
        shipmentType: isImport
          ? "importation"
          : isImport === false
          ? "exportation"
          : undefined,
        ...tableHeadFilterData,
      });
    },
  });

  const pagination = {
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
    totalCount: bidListData?.total || 0,
    perPage: filterData.perPage,
    setPerPage: (perPage: number) => setFilterData({ ...filterData, perPage }),
    currentPage: filterData.page,
    setCurrentPage: (page: number) => setFilterData({ ...filterData, page }),
  };

  const { adminUserList, adminDataForTableFilter } =
    ADMIN_USER_QUERY.useGetAdminUserList();

  const { AdminShipmentTable } = useAdminShipmentTable({
    bidListData,
    adminUserList,
    adminDataForTableFilter,
    TablePanel,
    TableHeadPanel: ShipmentTableHeadFilter,
    pagination,
  });

  return AdminShipmentTable;
};

export default withRequireAuth(Bid);
