import { Dispatch, SetStateAction } from "react";

import {
  AdminBidDetail,
  AdminBidPartnerQuoteReceiverList,
} from "@sellernote/_shared/src/types/forwarding/adminBid";

import ForExport from "./ForExport";
import ForImport from "./ForImport";

export default function RequestQuoteToPartnerModal({
  bidDetail,
  showsRequestQuoteModal,
  setShowsRequestQuoteModal,
  receiverList,
  handlePartnerQuoteListRefetch,
  isBidCopy,
  handleBidCopyModalClose,
  copiedBidId,
}: {
  bidDetail: AdminBidDetail;
  showsRequestQuoteModal: boolean;
  setShowsRequestQuoteModal: Dispatch<SetStateAction<boolean>>;
  receiverList: AdminBidPartnerQuoteReceiverList[];
  handlePartnerQuoteListRefetch?: () => void;
  isBidCopy: boolean;
  handleBidCopyModalClose?: () => void;
  copiedBidId?: number;
}) {
  if (!bidDetail) {
    return null;
  }

  /**
   * 수입에만 N컨테이너가 적용된 상태에서 코드 내부 분기로는 복잡도가 높아져서 모듈 자체를 분리함.
   */
  return bidDetail.isImport ? (
    <ForImport
      bidDetail={bidDetail}
      showsRequestQuoteModal={showsRequestQuoteModal}
      setShowsRequestQuoteModal={setShowsRequestQuoteModal}
      receiverList={receiverList}
      handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
      isBidCopy={isBidCopy}
      handleBidCopyModalClose={handleBidCopyModalClose}
      copiedBidId={copiedBidId}
    />
  ) : (
    <ForExport
      bidDetail={bidDetail}
      showsRequestQuoteModal={showsRequestQuoteModal}
      setShowsRequestQuoteModal={setShowsRequestQuoteModal}
      receiverList={receiverList}
      handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
      isBidCopy={isBidCopy}
      handleBidCopyModalClose={handleBidCopyModalClose}
      copiedBidId={copiedBidId}
    />
  );
}
