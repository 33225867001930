import { CREATE_BID_REQ_FCL_CONTAINERS_INFO } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { ArrayElement } from "@sellernote/_shared/src/types/common/customUtilityTypes";
import { ShipmentCreateContainersInfo } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

function getContainersInfoPayload({
  containersInfo,
  isOpenApiAuth,
}: {
  containersInfo: ShipmentCreateContainersInfo[] | undefined;
  isOpenApiAuth: boolean | undefined;
}): CREATE_BID_REQ_FCL_CONTAINERS_INFO {
  return (containersInfo || [])?.reduce(
    (
      a: CREATE_BID_REQ_FCL_CONTAINERS_INFO,
      containerInfo,
      containerInfoIndex
    ) => {
      const joinedName =
        containerInfo.products
          ?.map((product) => {
            return product.productName;
          })
          .join(",") ?? "";

      const subList = containerInfo.containerList.map((v) => {
        const item: ArrayElement<CREATE_BID_REQ_FCL_CONTAINERS_INFO> = {
          // OPEN API 유저가 아니라면 일반 name을 사용
          name: isOpenApiAuth ? joinedName : containerInfo.name,
          isDangerous: containerInfo.isDangerous,
          itemGroup: containerInfoIndex + 1,
          quantity: v.quantity,
          containerType: v.containerType,
        };

        return item;
      });

      return [...a, ...subList];
    },
    []
  );
}

export { getContainersInfoPayload };
