import { useFormContext } from "react-hook-form";
import { Grid, Skeleton, Typography } from "@mui/material";

import { CHECK_IS_OCEANTICKET_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import useDefaultCheckPoints from "./useDefaultCheckPoints";

function CheckPoints({
  isOceanticket,
}: {
  isOceanticket: CHECK_IS_OCEANTICKET_RES | undefined;
}) {
  const { watch } = useFormContext<BidCreateFormData>();

  const freightType = watch("freightType");
  const bidType = watch("bidType");
  const incoterms = watch("incoterms");

  const {
    checkPointsRef,
    prevFreightType,
    prevBidType,
    prevIncoterms,
    getCheckPointsByFreightType,
  } = useDefaultCheckPoints({ isOceanticket });

  return (
    <Grid
      ref={checkPointsRef}
      item
      container
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="div">
          체크포인트
        </Typography>
      </Grid>

      {/* 체크포인트 조건 변경 시 제대로 재렌더링이 되지 않아 추가 */}
      {freightType !== prevFreightType ||
      bidType !== prevBidType ||
      incoterms !== prevIncoterms ? (
        <Skeleton height={200} />
      ) : (
        getCheckPointsByFreightType()
      )}
    </Grid>
  );
}

export default CheckPoints;
