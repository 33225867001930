import { Control } from "react-hook-form";
import { Grid } from "@mui/material";

import {
  AdminBidCopyContactingPartnerFormForExport,
  AdminBidDetail,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

type CellKey = "품명" | "품명(영문)";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명", width: 300 },
  { cellKey: "품명(영문)", label: "품명(영문)", width: 300 },
];

export default function CargoForGeneral({
  bidDetail,
  control,
}: {
  bidDetail: AdminBidDetail;
  control: Control<AdminBidCopyContactingPartnerFormForExport>;
}) {
  const bodyRowList: TableBodyRow<CellKey>[] = (bidDetail.bidItems || []).map(
    (item, index) => {
      return {
        rowKey: item.id,
        품명: {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`generalFreightInfo.${index}.itemName`}
                control={control}
                fullWidth
              />
            </Grid>
          ),
        },
        "품명(영문)": {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`generalFreightInfo.${index}.itemNameEn`}
                control={control}
                required={true}
                fullWidth
              />
            </Grid>
          ),
        },
      };
    }
  );

  return (
    <>
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
    </>
  );
}
