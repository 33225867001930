import { Dispatch, SetStateAction, useMemo } from "react";

import {
  AdminBidDetail,
  AdminBidPartnerQuoteReceiverList,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

import ConsolidationItemsNameForm from "./ConsolidationItemsNameForm";
import ContainersInfoNameForm from "./ContainersInfoNameForm";
import ProductsInfoNameForm from "./ProductsInfoNameForm";

export default function ForImport({
  bidDetail,
  showsRequestQuoteModal,
  setShowsRequestQuoteModal,
  receiverList,
  handlePartnerQuoteListRefetch,
  isBidCopy,
  handleBidCopyModalClose,
  copiedBidId,
}: {
  bidDetail: AdminBidDetail;
  showsRequestQuoteModal: boolean;
  setShowsRequestQuoteModal: Dispatch<SetStateAction<boolean>>;
  receiverList: AdminBidPartnerQuoteReceiverList[];
  handlePartnerQuoteListRefetch?: () => void;
  isBidCopy: boolean;
  handleBidCopyModalClose?: () => void;
  copiedBidId?: number;
}) {
  const needItemNameEn = useMemo(() => {
    const languageEnReceiver = receiverList.find((receiver) => {
      return receiver.language === "en";
    });

    if (languageEnReceiver) {
      return true;
    }

    return false;
  }, [receiverList]);

  const PartnerQuoteForm = useMemo(() => {
    if (bidDetail.serviceType === "consolidation") {
      return (
        <ConsolidationItemsNameForm
          bidDetail={bidDetail}
          receiverList={receiverList}
          handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
          needItemNameEn={needItemNameEn}
          isBidCopy={isBidCopy}
          handleBidCopyModalClose={handleBidCopyModalClose}
          copiedBidId={copiedBidId}
        />
      );
    }

    if (bidDetail.freightType === "FCL") {
      return (
        <ContainersInfoNameForm
          bidDetail={bidDetail}
          receiverList={receiverList}
          handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
          needItemNameEn={needItemNameEn}
          isBidCopy={isBidCopy}
          handleBidCopyModalClose={handleBidCopyModalClose}
          copiedBidId={copiedBidId}
        />
      );
    }

    return (
      <ProductsInfoNameForm
        bidDetail={bidDetail}
        receiverList={receiverList}
        handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
        needItemNameEn={needItemNameEn}
        isBidCopy={isBidCopy}
        handleBidCopyModalClose={handleBidCopyModalClose}
        copiedBidId={copiedBidId}
      />
    );
  }, [
    bidDetail,
    copiedBidId,
    handleBidCopyModalClose,
    handlePartnerQuoteListRefetch,
    isBidCopy,
    needItemNameEn,
    receiverList,
  ]);

  return (
    <Modal
      customModalBoxStyle={{
        maxWidth: "800px",
      }}
      isOpened={showsRequestQuoteModal}
      handleClose={() => setShowsRequestQuoteModal(false)}
      modalBody={PartnerQuoteForm}
    />
  );
}
