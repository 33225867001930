import { useForm } from "react-hook-form";
import { Button, Grid, Typography } from "@mui/material";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  AdminBidPartnerQuoteProductsInfoForm,
  AdminBidPartnerQuoteReceiverList,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

type CellKey = "품명" | "품명(영문)";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명", width: 300 },
  { cellKey: "품명(영문)", label: "품명(영문)", width: 300 },
];

function ProductsInfoNameForm({
  bidDetail,
  receiverList,
  handlePartnerQuoteListRefetch,
  needItemNameEn,
  isBidCopy,
  handleBidCopyModalClose,
  copiedBidId,
}: {
  bidDetail: AdminBidDetail;
  receiverList: AdminBidPartnerQuoteReceiverList[];
  handlePartnerQuoteListRefetch?: () => void;
  needItemNameEn: boolean;
  isBidCopy: boolean;
  handleBidCopyModalClose?: () => void;
  copiedBidId?: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminBidPartnerQuoteProductsInfoForm>({
    defaultValues: {
      productsInfo: bidDetail.bidItems.map((bidItem) => {
        const {
          isDangerous,
          canStack,
          unitSupply,
          volumeUnit,
          weightUnit,
          weight,
          packingType,
          vertical,
          horizontal,
          height,
          cbm,
          quantity,
        } = bidItem;
        return {
          itemName: bidItem.name,
          itemNameEn: "",
          isDangerous,
          isDouble: canStack,
          unitSupply,
          volumeUnit,
          weightUnit,
          weight,
          packingType,
          vertical,
          horizontal,
          height,
          cbm,
          quantity,
        };
      }),
      comment: "",
    },
  });

  const {
    mutate: requestPartnerQuoteForExport,
    ResponseHandler: ResponseHandlerOfRequestPartnerQuote,
  } = ADMIN_BID_QUERY.useRequestPartnerQuoteForExport(bidDetail.id);

  const handlePartnerQuoteRequest = () => {
    requestPartnerQuoteForExport(
      {
        comment: watch("comment"),
        generalFreightInfo: watch("productsInfo"),
        bidId: bidDetail.id,
        receiverList,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          handlePartnerQuoteListRefetch && handlePartnerQuoteListRefetch();
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const bodyRowList: TableBodyRow<CellKey>[] = (bidDetail.bidItems || []).map(
    (item, index) => {
      return {
        rowKey: item.id,
        품명: {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`productsInfo.${index}.itemName`}
                control={control}
                error={
                  errors.productsInfo &&
                  errors.productsInfo[index]?.itemName?.type === "required"
                }
                helperText={
                  errors.productsInfo &&
                  errors.productsInfo[index]?.itemName?.type === "required" &&
                  "필수 입력입니다."
                }
              />
            </Grid>
          ),
        },
        "품명(영문)": {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`productsInfo.${index}.itemNameEn`}
                fullWidth
                control={control}
                required={needItemNameEn}
                error={
                  errors.productsInfo &&
                  errors.productsInfo[index]?.itemNameEn?.type === "required"
                }
                helperText={
                  errors.productsInfo &&
                  errors.productsInfo[index]?.itemNameEn?.type === "required" &&
                  "필수 입력입니다."
                }
              />
            </Grid>
          ),
        },
      };
    }
  );

  return (
    <Grid
      container
      spacing={4}
      direction="row"
      component="form"
      onSubmit={handleSubmit(handlePartnerQuoteRequest)}
    >
      <Grid item>
        <Typography variant="h5" component="div">
          운임문의 요청
        </Typography>
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          화물
        </Typography>

        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          남길 말
        </Typography>

        <TextFieldWithReactHookForm
          name={"comment"}
          control={control}
          fullWidth={true}
        />
      </Grid>

      <Grid item container justifyContent={"end"}>
        <Grid item>
          <Button variant="contained" type="submit">
            운임문의 요청
          </Button>
        </Grid>

        {isBidCopy && handleBidCopyModalClose && (
          <Grid item>
            <Button
              variant="outlined"
              type="submit"
              onClick={handleBidCopyModalClose}
            >
              돌아가기
            </Button>
          </Grid>
        )}
      </Grid>

      {ResponseHandlerOfRequestPartnerQuote}
    </Grid>
  );
}

export default ProductsInfoNameForm;
