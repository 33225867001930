import { useForm } from "react-hook-form";
import { Box, Button, colors, Grid, Typography } from "@mui/material";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  AdminBidPartnerQuoteConsolidationItemForExport,
  AdminBidPartnerQuoteConsolidationItemsForm,
  AdminBidPartnerQuoteReceiverList,
  ConsolidationItemList,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getConsolidationItemListForExport } from "@sellernote/_shared/src/utils/forwarding/bid";
import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

type CellKey = "품명" | "품명(영문)";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명", width: 300 },
  { cellKey: "품명(영문)", label: "품명(영문)", width: 300 },
];

function ConsolidationItemsNameForm({
  bidDetail,
  receiverList,
  handlePartnerQuoteListRefetch,
  needItemNameEn,
  isBidCopy,
  handleBidCopyModalClose,
  copiedBidId,
}: {
  bidDetail: AdminBidDetail;
  receiverList: AdminBidPartnerQuoteReceiverList[];
  handlePartnerQuoteListRefetch?: () => void;
  needItemNameEn: boolean;
  isBidCopy: boolean;
  handleBidCopyModalClose?: () => void;
  copiedBidId?: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const sellerList = getConsolidationItemListForExport(bidDetail.bidItems);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminBidPartnerQuoteConsolidationItemsForm>({
    defaultValues: {
      consolidationFreightInfo: sellerList.reduce((acc, cur) => {
        const consolidationItemArray = cur.itemList.map((item) => {
          return {
            itemName: item.name,
            itemNameEn: "",
            address: item.address || "",
            volumeUnit: item.volumeUnit,
            height: item.height,
            vertical: item.vertical,
            horizontal: item.horizontal,
            quantity: item.quantity,
            weight: item.weight,
            weightUnit: item.weightUnit,
            cbm: item.cbm,
            packagingType: item.packingType,
          };
        });

        return [...acc, consolidationItemArray];
      }, [] as AdminBidPartnerQuoteConsolidationItemForExport[][]),
      comment: "",
    },
  });

  const {
    mutate: requestPartnerQuote,
    ResponseHandler: ResponseHandlerOfRequestPartnerQuote,
  } = ADMIN_BID_QUERY.useRequestPartnerQuoteForExport(bidDetail.id);

  const handlePartnerQuoteRequest = () => {
    requestPartnerQuote(
      {
        comment: watch("comment"),
        consolidationFreightInfo: watch("consolidationFreightInfo"),
        bidId: bidDetail.id,
        receiverList,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          handlePartnerQuoteListRefetch && handlePartnerQuoteListRefetch();
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const createBodyRowList = ({
    sellerData,
    consolidationItemsIndex,
  }: {
    sellerData: ConsolidationItemList;
    consolidationItemsIndex: number;
  }): TableBodyRow<CellKey>[] => {
    return sellerData.itemList.map((item, itemIndex) => {
      return {
        rowKey: item.id,
        품명: {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`consolidationFreightInfo.${consolidationItemsIndex}.${itemIndex}.itemName`}
                fullWidth
                control={control}
                error={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.itemName?.type === "required"
                }
                helperText={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.itemName?.type === "required" &&
                  "필수 입력입니다."
                }
              />
            </Grid>
          ),
        },
        "품명(영문)": {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`consolidationFreightInfo.${consolidationItemsIndex}.${itemIndex}.itemNameEn`}
                fullWidth
                control={control}
                required={needItemNameEn}
                error={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.itemNameEn?.type === "required"
                }
                helperText={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.itemNameEn?.type === "required" &&
                  "필수 입력입니다."
                }
              />
            </Grid>
          ),
        },
      };
    });
  };

  return (
    <Grid
      container
      spacing={4}
      direction="row"
      component="form"
      onSubmit={handleSubmit(handlePartnerQuoteRequest)}
    >
      <Grid item>
        <Typography variant="h5" component="div">
          운임문의 요청
        </Typography>
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          화물
        </Typography>

        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
          {sellerList.map((sellerData, consolidationItemsIndex) => {
            const bodyRowList = createBodyRowList({
              sellerData,
              consolidationItemsIndex,
            });

            return (
              <Box key={consolidationItemsIndex}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  color={colors.blue[600]}
                >
                  {`수출자${consolidationItemsIndex + 1}`}
                </Typography>

                <SADSTable
                  headCellList={headCellList}
                  bodyRowList={bodyRowList}
                />
              </Box>
            );
          })}
        </Box>
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          남길 말
        </Typography>

        <TextFieldWithReactHookForm
          name={"comment"}
          control={control}
          fullWidth={true}
        />
      </Grid>

      <Grid item container justifyContent={"end"}>
        <Grid item>
          <Button variant="contained" type="submit">
            운임문의 요청
          </Button>
        </Grid>

        {isBidCopy && handleBidCopyModalClose && (
          <Grid item>
            <Button
              variant="outlined"
              type="submit"
              onClick={handleBidCopyModalClose}
            >
              돌아가기
            </Button>
          </Grid>
        )}
      </Grid>

      {ResponseHandlerOfRequestPartnerQuote}
    </Grid>
  );
}

export default ConsolidationItemsNameForm;
