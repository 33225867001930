import { useParams } from "react-router-dom";

import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

import ChangeQuotation from "./ChangeQuotation";

const ChangeQuotationDetailByFreightType = () => {
  const { id, freightType }: { id: string; freightType: FreightType } =
    useParams();

  const { data: bidDetail, isFetching: isBidDetailFetching } =
    ADMIN_BID_QUERY.useGetAdminBidDetail({
      bidId: Number(id),
    });

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
    countryTarget: bidDetail?.locale,
  });

  const { data: linerList } = ADMIN_COMMON_QUERY.useGetLinerList();

  const { data: applyItems } = ADMIN_BID_QUERY.useGetAdminBidApplyItems({});

  if (
    !bidDetail ||
    !exchangeRateList ||
    !applyItems ||
    !linerList ||
    isBidDetailFetching
  ) {
    return (
      <Layout breadcrumbs={["입찰 관리"]} title={"입찰 수정"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout breadcrumbs={["입찰 관리"]} title={"입찰 수정"}>
      <ChangeQuotation
        bidDetail={bidDetail}
        exchangeRateList={exchangeRateList}
        applyItems={applyItems}
        linerList={linerList}
      />
    </Layout>
  );
};

export default withRequireAuth(ChangeQuotationDetailByFreightType);
