import { Control, Controller } from "react-hook-form";
import { Typography } from "@mui/material";

import { REQUEST_PARTNER_EMAIL_FOR_IMPORT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import { AdminBidPartnerEmailFormForExport } from "@sellernote/_shared/src/types/forwarding/adminBid";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";

export default function CommentForm({
  usedBy,
  control,
}:
  | {
      usedBy: "import";
      control: Control<REQUEST_PARTNER_EMAIL_FOR_IMPORT_REQ>;
    }
  | {
      usedBy: "export";
      control: Control<AdminBidPartnerEmailFormForExport>;
    }) {
  return (
    <>
      <Typography variant="h6" component="div">
        코멘트
      </Typography>

      {/* control type 오류를 해결하기위헤 이렇게 처리 */}
      {usedBy === "import" && (
        <Controller
          control={control}
          name={"comment"}
          render={({ field: { ref, ...field } }) => {
            return (
              <TextField
                {...field}
                variant={"standard"}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
                inputRef={ref}
              />
            );
          }}
        />
      )}
      {usedBy === "export" && (
        <Controller
          control={control}
          name={"comment"}
          render={({ field: { ref, ...field } }) => {
            return (
              <TextField
                {...field}
                variant={"standard"}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
                inputRef={ref}
              />
            );
          }}
        />
      )}
    </>
  );
}
