import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useHistory } from "react-router";
import { RecoilRoot } from "recoil";
import Router from "Router";
import { css } from "styled-components";

import {
  IS_READY_FOR_MSW_TOOL,
  IS_UNDER_LOCAL_DEVELOPMENT,
} from "@sellernote/_shared/src/constants";
import {
  I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM,
  initI18nInstanceForDesignSystem,
} from "@sellernote/_shared/src/i18n/i18nForDesignSystem";
import MSWTool from "@sellernote/_shared/src/mocks/MSWTool";
import { JotaiDevtools } from "@sellernote/_shared/src/services/jotai";
import { REACT_QUERY_CLIENT_CONFIG } from "@sellernote/_shared/src/services/query";
import { showVerticalScrollBarAlways } from "@sellernote/_shared/src/styles/functions";
import { validateOrRefreshAuthToken } from "@sellernote/_shared/src/utils/common/auth";
import { MINUTE_AS_MILLISECONDS } from "@sellernote/_shared/src/utils/common/date";
import { printBuildInfo } from "@sellernote/_shared/src/utils/common/etc";
import GlobalStyle from "@sellernote/_sds-v1/src/styles/global";

export interface TokenCommon {
  sub: number;
  type: "forwarder" | "customs" | "consignee";
  typeId: number;
  iat: number;
  exp: number;
}

const App: React.FC = () => {
  useEffect(() => {
    printBuildInfo();
  }, []);

  const history = useHistory();

  const [queryClient] = useState(
    () => new QueryClient(REACT_QUERY_CLIENT_CONFIG)
  );

  const { t } = useTranslation(I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM);
  initI18nInstanceForDesignSystem({ Trans, t });

  // 5분마다 토큰 유효성을 검사
  useEffect(() => {
    const interval = setInterval(
      () =>
        validateOrRefreshAuthToken({
          loginPagePath: "/login",
          tokenRefreshAPIUrl: `${process.env.REACT_APP_ADMIN_URL}/auth/refresh`,
        }),
      MINUTE_AS_MILLISECONDS * 5
    );

    return () => clearInterval(interval);
  }, [history]);

  return (
    <>
      <RecoilRoot>
        {IS_READY_FOR_MSW_TOOL && <MSWTool type="web" />}

        <QueryClientProvider client={queryClient}>
          {IS_UNDER_LOCAL_DEVELOPMENT && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}

          <JotaiDevtools>
            <Router>
              <GlobalStyle
                appCustomReset={css`
                  * {
                    ${showVerticalScrollBarAlways()}
                  }
                `}
              />
            </Router>
          </JotaiDevtools>
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
};

export default App;
