import { useForm } from "react-hook-form";
import { Box, Button, colors, Grid, Typography } from "@mui/material";

import { REQUEST_PARTNER_QUOTE_FOR_IMPORT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  AdminBidPartnerQuoteConsolidationItemForImport,
  AdminBidPartnerQuoteReceiverList,
  ConsolidationItemList,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getConsolidationItemListForImport } from "@sellernote/_shared/src/utils/forwarding/bid";
import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

type CellKey = "품명" | "품명(영문)";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명", width: 300 },
  { cellKey: "품명(영문)", label: "품명(영문)", width: 300 },
];

function ConsolidationItemsNameForm({
  bidDetail,
  receiverList,
  handlePartnerQuoteListRefetch,
  needItemNameEn,
  isBidCopy,
  handleBidCopyModalClose,
  copiedBidId,
}: {
  bidDetail: AdminBidDetail;
  receiverList: AdminBidPartnerQuoteReceiverList[];
  handlePartnerQuoteListRefetch?: () => void;
  needItemNameEn: boolean;
  isBidCopy: boolean;
  handleBidCopyModalClose?: () => void;
  copiedBidId?: number;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const sellerList = getConsolidationItemListForImport(bidDetail.itemGroupList);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    consolidationFreightInfo: AdminBidPartnerQuoteConsolidationItemForImport[][];
    comment: REQUEST_PARTNER_QUOTE_FOR_IMPORT_REQ["comment"];
  }>({
    defaultValues: {
      consolidationFreightInfo: sellerList.map((seller) => {
        const result: AdminBidPartnerQuoteConsolidationItemForImport[] =
          seller.itemList.map((v) => {
            const subResult: AdminBidPartnerQuoteConsolidationItemForImport = {
              item: {
                itemName: v.name,
                itemNameEn: v.engName,
                address: v.address || "",
                volumeUnit: v.volumeUnit,
                height: v.height,
                vertical: v.vertical,
                horizontal: v.horizontal,
                quantity: v.quantity,
                weight: v.weight,
                weightUnit: v.weightUnit,
                cbm: v.cbm,
                packagingType: v.packingType,
              },
            };

            return subResult;
          });

        return result;
      }),
      comment: "",
    },
  });

  const {
    mutate: requestPartnerQuote,
    ResponseHandler: ResponseHandlerOfRequestPartnerQuote,
  } = ADMIN_BID_QUERY.useRequestPartnerQuoteForImport(bidDetail.id);

  const { mutate: copyPartnerQuote } = ADMIN_BID_QUERY.useCopyPartnerQuote();

  const handlePartnerQuoteRequest = () => {
    if (isBidCopy) {
      copyPartnerQuote(
        {
          comment: watch("comment"),
          consolidationFreightInfo: watch("consolidationFreightInfo"),
          bidId: isBidCopy && copiedBidId ? copiedBidId : bidDetail.id,
        },
        {
          onSuccess: () => {
            handleBidCopyModalClose && handleBidCopyModalClose();
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
      return;
    }

    requestPartnerQuote(
      {
        comment: watch("comment"),
        consolidationFreightInfo: watch("consolidationFreightInfo"),
        bidId: bidDetail.id,
        receiverList,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          handlePartnerQuoteListRefetch && handlePartnerQuoteListRefetch();
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const createBodyRowList = ({
    sellerData,
    consolidationItemsIndex,
  }: {
    sellerData: ConsolidationItemList;
    consolidationItemsIndex: number;
  }): TableBodyRow<CellKey>[] => {
    return sellerData.itemList.map((item, itemIndex) => {
      return {
        rowKey: item.id,
        품명: {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`consolidationFreightInfo.${consolidationItemsIndex}.${itemIndex}.item.itemName`}
                fullWidth
                control={control}
                error={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.item?.itemName?.type === "required"
                }
                helperText={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.item?.itemName?.type === "required" &&
                  "필수 입력입니다."
                }
              />
            </Grid>
          ),
        },
        "품명(영문)": {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`consolidationFreightInfo.${consolidationItemsIndex}.${itemIndex}.item.itemNameEn`}
                fullWidth
                control={control}
                required={needItemNameEn}
                error={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.item?.itemNameEn?.type === "required"
                }
                helperText={
                  errors.consolidationFreightInfo &&
                  errors.consolidationFreightInfo[consolidationItemsIndex]?.[
                    itemIndex
                  ]?.item?.itemNameEn?.type === "required" &&
                  "필수 입력입니다."
                }
              />
            </Grid>
          ),
        },
      };
    });
  };

  return (
    <Grid
      container
      spacing={4}
      direction="row"
      component="form"
      onSubmit={handleSubmit(handlePartnerQuoteRequest)}
    >
      <Grid item>
        <Typography variant="h5" component="div">
          운임문의 요청
        </Typography>
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          화물
        </Typography>

        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
          {sellerList.map((sellerData, consolidationItemsIndex) => {
            const bodyRowList = createBodyRowList({
              sellerData,
              consolidationItemsIndex,
            });

            return (
              <Box key={consolidationItemsIndex}>
                <Typography
                  variant="subtitle1"
                  component="div"
                  color={colors.blue[600]}
                >
                  {`수출자${consolidationItemsIndex + 1}`}
                </Typography>

                <SADSTable
                  headCellList={headCellList}
                  bodyRowList={bodyRowList}
                />
              </Box>
            );
          })}
        </Box>
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          코멘트
        </Typography>

        <TextFieldWithReactHookForm
          name={"comment"}
          control={control}
          fullWidth={true}
        />
      </Grid>

      <Grid item container justifyContent={"end"}>
        <Grid item>
          <Button variant="contained" type="submit">
            운임문의 요청
          </Button>
        </Grid>

        {isBidCopy && handleBidCopyModalClose && (
          <Grid item>
            <Button
              variant="outlined"
              type="submit"
              onClick={handleBidCopyModalClose}
            >
              돌아가기
            </Button>
          </Grid>
        )}
      </Grid>

      {ResponseHandlerOfRequestPartnerQuote}
    </Grid>
  );
}

export default ConsolidationItemsNameForm;
