import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Button, colors, Grid, Typography } from "@mui/material";

import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { AdminBidPartnerEmailFormForExport } from "@sellernote/_shared/src/types/forwarding/adminBid";
import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

import { useBidPartnerContext } from "../../../hooks/useBidPartnerContext";

import CommentForm from "../../components/CommentForm";
import CompanyNameForm from "../../components/CompanyNameForm";
import MailCommentModal from "../../components/MailCommentModal";

type CellKey = "품명" | "품명(영문)";

function ProductsInfoEmailForm() {
  const queryClient = useQueryClient();

  const {
    partnerBusinessArea,
    bidAccountPayableId,
    language,
    bidDetail,
    showsMailCommentModal,
    partnerName,
    handleSnackbarOpen,
  } = useBidPartnerContext();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminBidPartnerEmailFormForExport>({
    defaultValues: {
      bidId: bidDetail.id,
      engCompany: bidDetail.user.engCompany,
      generalFreightInfo: bidDetail.bidItems.map((bidItem) => {
        return {
          itemName: bidItem.name,
          itemNameEn: partnerBusinessArea === "foreign" ? "" : undefined,
          isDangerous: bidItem.isDangerous,
          isDouble: bidItem.canStack,
          unitSupply: bidItem.unitSupply,
          volumeUnit: bidItem.volumeUnit,
          weightUnit: bidItem.weightUnit,
          packingType: bidItem.packingType,
          vertical: bidItem.vertical,
          horizontal: bidItem.horizontal,
          height: bidItem.height,
          cbm: bidItem.cbm,
          weight: bidItem.weight,
          quantity: bidItem.quantity,
        };
      }),
      comment: "",
      partnerManagerType: partnerBusinessArea,
    },
  });

  const headCellList: TableHeadCell<CellKey>[] = [
    { cellKey: "품명", label: "품명", width: 300 },
    {
      cellKey: "품명(영문)",
      label: "품명(영문)",
      width: 300,
      hidden: language !== "en",
    },
  ];

  const bodyRowList: TableBodyRow<CellKey>[] = (bidDetail.bidItems || []).map(
    (item, index) => {
      return {
        rowKey: item.id,
        품명: {
          value: (
            <Grid container>
              <TextFieldWithReactHookForm
                name={`generalFreightInfo.${index}.itemName`}
                control={control}
                fullWidth
                error={
                  errors.generalFreightInfo &&
                  errors.generalFreightInfo[index]?.itemName?.type ===
                    "required"
                }
                helperText={
                  errors.generalFreightInfo &&
                  errors.generalFreightInfo[index]?.itemName?.type ===
                    "required" &&
                  "필수 입력입니다."
                }
              />
            </Grid>
          ),
        },
        "품명(영문)": {
          value: (
            <Grid container>
              {language === "en" ? (
                <TextFieldWithReactHookForm
                  name={`generalFreightInfo.${index}.itemNameEn`}
                  control={control}
                  fullWidth
                  required={true}
                  error={
                    errors.generalFreightInfo &&
                    errors.generalFreightInfo[index]?.itemNameEn?.type ===
                      "required"
                  }
                  helperText={
                    errors.generalFreightInfo &&
                    errors.generalFreightInfo[index]?.itemNameEn?.type ===
                      "required" &&
                    "필수 입력입니다."
                  }
                />
              ) : null}
            </Grid>
          ),
        },
      };
    }
  );

  const {
    mutate: requestPartnerEmailForExport,
    ResponseHandler: ResponseHandlerOfRequestPartnerEmailForExport,
  } = ADMIN_BID_QUERY.useRequestPartnerEmailForExport();

  const handleExportShipmentPartnerEmailRequest = () => {
    requestPartnerEmailForExport(
      { ...watch(), bidAccountPayableId },
      {
        onSuccess: () => {
          // 수출에서는 팝업금지 요청을 하지 않음
          handleSnackbarOpen("요청에 성공했습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: ({ response }) => {
          if (response?.data?.code === 400) {
            handleSnackbarOpen("수입자 정보를 먼저 입력해야 합니다.", "error");
            return;
          }

          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return (
    <Grid container spacing={4} direction="row" component="form">
      <Grid item>
        <Typography variant="h5" component="div">
          파트너(
          <span style={{ color: colors.grey[600] }}>{partnerName}</span>) 컨택
        </Typography>
      </Grid>

      <Grid item width={"100%"}>
        <CompanyNameForm
          usedBy="export"
          control={control}
          errors={errors}
          bidUser={bidDetail.user}
        />
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          화물
        </Typography>

        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>

      <Grid item width={"100%"}>
        <CommentForm usedBy="export" control={control} />
      </Grid>

      <Grid item container justifyContent={"end"} gap={2}>
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit(handleExportShipmentPartnerEmailRequest)}
          >
            컨택 요청
          </Button>
        </Grid>
      </Grid>

      {showsMailCommentModal && <MailCommentModal />}
      {ResponseHandlerOfRequestPartnerEmailForExport}
    </Grid>
  );
}

export default ProductsInfoEmailForm;
