import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Button, colors, Grid, Typography } from "@mui/material";

import { REQUEST_PARTNER_EMAIL_FOR_IMPORT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { AdminBidPartnerQuoteContainerInfoForImport } from "@sellernote/_shared/src/types/forwarding/adminBid";
import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-forwarding-admin/src/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

import { useBidPartnerContext } from "../../../hooks/useBidPartnerContext";

import CommentForm from "../../components/CommentForm";
import CompanyNameForm from "../../components/CompanyNameForm";
import MailCommentModal from "../../components/MailCommentModal";

type CellKey = "품명" | "품명(영문)";

function ContainersInfoEmailForm() {
  const queryClient = useQueryClient();

  const {
    partnerBusinessArea,
    bidAccountPayableId,
    language,
    bidDetail,
    showsMailCommentModal,
    setShowsMailCommentModal,
    setShowsRequestEmailToPartner,
    partnerName,
    handleSnackbarOpen,
  } = useBidPartnerContext();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<REQUEST_PARTNER_EMAIL_FOR_IMPORT_REQ>({
    defaultValues: {
      bidId: bidDetail.id,
      engCompany: bidDetail.user.engCompany,
      generalFreightInfo: bidDetail.itemGroupList.map((v) => {
        const result: AdminBidPartnerQuoteContainerInfoForImport = {
          item: {
            itemName: v.item.name,
            itemNameEn: v.item.engName,
            isDangerous: v.item.isDangerous,
          },
          itemGroup: v.itemGroup,
          containerInfo: v.containerInfo,
        };

        return result;
      }),
      comment: "",
      partnerManagerType: partnerBusinessArea,
    },
  });

  const headCellList: TableHeadCell<CellKey>[] = [
    { cellKey: "품명", label: "품명", width: 300 },
    {
      cellKey: "품명(영문)",
      label: "품명(영문)",
      width: 300,
      hidden: language !== "en",
    },
  ];

  const bodyRowList: TableBodyRow<CellKey>[] = (
    bidDetail.itemGroupList || []
  ).map(({ item }, index) => {
    return {
      rowKey: item.id,
      품명: {
        value: (
          <Grid container>
            <TextFieldWithReactHookForm
              name={`generalFreightInfo.${index}.item.itemName`}
              control={control}
              fullWidth
              error={
                errors.generalFreightInfo &&
                errors.generalFreightInfo[index]?.item?.itemName?.type ===
                  "required"
              }
              helperText={
                errors.generalFreightInfo &&
                errors.generalFreightInfo[index]?.item?.itemName?.type ===
                  "required" &&
                "필수 입력입니다."
              }
            />
          </Grid>
        ),
      },
      "품명(영문)": {
        value: (
          <Grid container>
            {language === "en" ? (
              <TextFieldWithReactHookForm
                name={`generalFreightInfo.${index}.item.itemNameEn`}
                control={control}
                required={true}
                fullWidth
                error={
                  errors.generalFreightInfo &&
                  errors.generalFreightInfo[index]?.item?.itemNameEn?.type ===
                    "required"
                }
                helperText={
                  errors.generalFreightInfo &&
                  errors.generalFreightInfo[index]?.item?.itemNameEn?.type ===
                    "required" &&
                  "필수 입력입니다."
                }
              />
            ) : null}
          </Grid>
        ),
      },
    };
  });

  const {
    mutate: requestPartnerEmailForImport,
    ResponseHandler: ResponseHandlerOfRequestPartnerEmailForImport,
  } = ADMIN_BID_QUERY.useRequestPartnerEmailForImport();

  const handlePartnerQuoteRequest = () => {
    requestPartnerEmailForImport(
      { ...watch(), bidAccountPayableId },
      {
        onSuccess: ({ data }) => {
          if (data) {
            setShowsMailCommentModal(true);
            handleSnackbarOpen("요청에 성공했습니다.");
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: bidDetail.id,
              })
            );
            return;
          }

          handleSnackbarOpen("요청에 성공했습니다.");
          setShowsRequestEmailToPartner(false);
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: () => handleSnackbarOpen("요청에 실패했습니다.", "error"),
      }
    );
  };

  return (
    <Grid container spacing={4} direction="row" component="form">
      <Grid item>
        <Typography variant="h5" component="div">
          파트너(
          <span style={{ color: colors.grey[600] }}>{partnerName}</span>) 컨택
        </Typography>
      </Grid>

      <Grid item width={"100%"}>
        <CompanyNameForm
          usedBy="import"
          control={control}
          errors={errors}
          bidUser={bidDetail.user}
        />
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          화물
        </Typography>

        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>

      <Grid item width={"100%"}>
        <CommentForm usedBy="import" control={control} />
      </Grid>

      <Grid item container justifyContent={"end"} gap={2}>
        <Grid item>
          <Button
            variant="contained"
            type="submit"
            onClick={handleSubmit(handlePartnerQuoteRequest)}
          >
            컨택 요청
          </Button>
        </Grid>
      </Grid>

      {showsMailCommentModal && <MailCommentModal />}
      {ResponseHandlerOfRequestPartnerEmailForImport}
    </Grid>
  );
}

export default ContainersInfoEmailForm;
