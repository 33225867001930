import { Dispatch, SetStateAction } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Grid,
  Typography,
} from "@mui/material";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  AdminBidCopyContactingPartnerFormForExport,
  AdminBidDetail,
  AdminBidPartnerQuoteConsolidationItemForExport,
  BidCopyStatus,
  BidCopyTarget,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  checkBidPartnerLanguageIsEn,
  checkIfBidHaveDomainPartner,
} from "@sellernote/_shared/src/utils/forwarding/adminBid";
import { getConsolidationItemListForExport } from "@sellernote/_shared/src/utils/forwarding/bid";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import CargoForConsol from "./CargoForConsol";
import CargoForGeneral from "./CargoForGeneral";

export default function ContactingPartnerModalForExport({
  showsContactingPartnerModal,
  setShowsContactingPartnerModal,
  bidDetail,
  copyStatusOfRadio,
  bidCopyTargetValue,
  setShowsBidCopyModal,
}: {
  showsContactingPartnerModal: boolean;
  setShowsContactingPartnerModal: Dispatch<SetStateAction<boolean>>;
  bidDetail: AdminBidDetail;
  copyStatusOfRadio: BidCopyStatus;
  bidCopyTargetValue: BidCopyTarget;
  setShowsBidCopyModal: Dispatch<SetStateAction<boolean>>;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const { mutate: copyShipmentForExport, isLoading } =
    ADMIN_BID_QUERY.useCopyShipmentForExport();

  const handleAdminBidCopy = () => {
    copyShipmentForExport(
      {
        pathParams: {
          shipmentId: bidDetail.id,
        },
        targetStatus: copyStatusOfRadio,
        copyTarget: bidCopyTargetValue,
        commentd: watch("commentd"),
        commentf: watch("commentf"),
      },
      {
        onSuccess: (res) => {
          history.push(`/bid/detail/${res.data.id}`);
          setShowsBidCopyModal(false);
          setShowsContactingPartnerModal(false);
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const sellerList =
    bidDetail.serviceType === "consolidation"
      ? getConsolidationItemListForExport(bidDetail.bidItems)
      : undefined;

  const getGeneralFrightInfo = () => {
    if (bidDetail.serviceType === "consolidation") return;

    if (bidDetail.freightType === "FCL") {
      return bidDetail.bidItems.map((bidItem) => {
        return {
          itemName: bidItem.name,
          itemNameEn: checkBidPartnerLanguageIsEn(bidDetail, "foreign")
            ? ""
            : undefined,
          isDangerous: bidItem.isDangerous,
          containerType: bidItem.containerType,
          quantity: bidItem.quantity,
        };
      });
    }

    return bidDetail.bidItems.map((bidItem) => {
      return {
        itemName: bidItem.name,
        itemNameEn: checkBidPartnerLanguageIsEn(bidDetail, "foreign")
          ? ""
          : undefined,
        isDangerous: bidItem.isDangerous,
        isDouble: bidItem.canStack,
        unitSupply: bidItem.unitSupply,
        volumeUnit: bidItem.volumeUnit,
        weightUnit: bidItem.weightUnit,
        packingType: bidItem.packingType,
        vertical: bidItem.vertical,
        horizontal: bidItem.horizontal,
        height: bidItem.height,
        cbm: bidItem.cbm,
        weight: bidItem.weight,
        quantity: bidItem.quantity,
      };
    });
  };

  const getConsolidationFreightInfo = () => {
    if (bidDetail.serviceType !== "consolidation") return;

    return sellerList?.reduce((acc, cur) => {
      const consolidationItemArray = cur.itemList.map((item) => {
        return {
          itemName: item.name,
          itemNameEn: checkBidPartnerLanguageIsEn(bidDetail, "foreign")
            ? ""
            : undefined,
          address: item.address || "",
          volumeUnit: item.volumeUnit,
          height: item.height,
          vertical: item.vertical,
          horizontal: item.horizontal,
          quantity: item.quantity,
          weight: item.weight,
          weightUnit: item.weightUnit,
          cbm: item.cbm,
          packagingType: item.packingType,
          exporter: item.exporter,
        };
      });

      return [...acc, consolidationItemArray];
    }, [] as AdminBidPartnerQuoteConsolidationItemForExport[][]);
  };

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminBidCopyContactingPartnerFormForExport>({
    defaultValues: {
      commentf: "",
      commentd: "",
      generalFreightInfo: getGeneralFrightInfo(),
      consolidationFreightInfo: getConsolidationFreightInfo(),
    },
  });

  return (
    <Modal
      customModalBoxStyle={{
        maxWidth: "800px",
      }}
      isOpened={showsContactingPartnerModal}
      handleClose={() => setShowsContactingPartnerModal(false)}
      modalBody={
        <Grid
          container
          spacing={4}
          direction="row"
          component="form"
          onSubmit={handleSubmit(handleAdminBidCopy)}
        >
          <Grid item>
            <Typography variant="h5" component="div">
              파트너 컨택 요청
            </Typography>
          </Grid>

          <Grid item width={"100%"}>
            <Typography variant="h6" component="div">
              화물
            </Typography>

            {bidDetail.serviceType === "consolidation" ? (
              <CargoForConsol
                bidDetail={bidDetail}
                sellerList={sellerList}
                control={control}
              />
            ) : (
              <CargoForGeneral bidDetail={bidDetail} control={control} />
            )}
          </Grid>

          <Grid item width={"100%"}>
            <Typography variant="h6" component="div">
              코멘트
            </Typography>

            <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
              {checkIfBidHaveDomainPartner(bidDetail, "domestic") && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color={colors.blue[600]}
                  >
                    국내파트너 코멘트
                  </Typography>

                  <TextFieldWithReactHookForm
                    name={`commentd`}
                    control={control}
                    fullWidth
                  />
                </Box>
              )}

              {checkIfBidHaveDomainPartner(bidDetail, "foreign") && (
                <Box>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    color={colors.blue[600]}
                  >
                    해외파트너 코멘트
                  </Typography>

                  <TextFieldWithReactHookForm
                    name={`commentf`}
                    control={control}
                    fullWidth
                  />
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item container justifyContent={"end"} gap={2}>
            <Grid item>
              <Button disabled={isLoading} variant="contained" type="submit">
                {isLoading ? <CircularProgress size={25} /> : "컨택 요청"}
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                onClick={() => setShowsContactingPartnerModal(false)}
              >
                돌아가기
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
