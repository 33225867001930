import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import {
  GET_ADMIN_BID_APPLY_ITEMS_RES,
  GET_ADMIN_BID_DETAIL_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";
import { Liner } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getLinerId,
  getQuotationDataCurrency,
  replaceBrTagsWithNewline,
} from "@sellernote/_shared/src/utils/forwarding/adminBid";
import {
  findExchangeRate,
  getAllTotalItemPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import useGetNewCurrencyQuotation from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetNewCurrencyQuotation";
import useSnackbar from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSnackbar";

import BidQuotation from "pages/bid/BidQuotation";

function ConfirmQuotation({
  bidDetail,
  exchangeRateList,
  applyItems,
  linerList,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
  exchangeRateList: ExchangeRate[];
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES;
  linerList: Liner[];
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const setTabDefaultActiveKey = useSetRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_DETAIL_TAB_DEFAULT_ACTIVE_KEY
  );

  const { mutate: confirmBidQuote, isLoading } =
    ADMIN_BID_QUERY.useConfirmBidQuote();

  const { mutate: confirmExportBidQuote, isLoading: isExportLoading } =
    ADMIN_BID_QUERY.useConfirmExportBidQuote();

  const { quotationWithIsTaxExempt } = useGetNewCurrencyQuotation({
    bidDetail,
    exchangeRateList,
    type: "confirm",
  });

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplyBidFormData>({
    defaultValues: {
      otherFeeData: quotationWithIsTaxExempt.otherFee || [],
      freightPaymentType: quotationWithIsTaxExempt.freightPaymentType,
      liner: quotationWithIsTaxExempt.liner,
      freightFeeData: quotationWithIsTaxExempt.freightFee || [],
      domesticFeeData: quotationWithIsTaxExempt.domesticFee || [],
      inlandFeeData: quotationWithIsTaxExempt.inlandFee || [],
      localFeeData: quotationWithIsTaxExempt.localFee || [],
      taxFeeData: quotationWithIsTaxExempt.taxFee || [],
      leadtime: quotationWithIsTaxExempt.leadtime,
      comment: replaceBrTagsWithNewline(
        bidDetail.quotationForwarders[0].comment
      ),
      expiredAt: quotationWithIsTaxExempt.expiredAt,
      isTransit: quotationWithIsTaxExempt.isTransit,
    },
  });

  const handleQuotationConfirm = useCallback(() => {
    const requestPayload = {
      pathParams: {
        bidId: bidDetail.id,
      },
      quotationData: {
        currency: getQuotationDataCurrency({
          localFee: watch("localFeeData"),
          freightFee: watch("freightFeeData"),
          domesticFee: watch("domesticFeeData"),
          inlandFee: watch("inlandFeeData"),
          otherFee: watch("otherFeeData"),
          taxFee: watch("taxFeeData"),
          exchangeRateList: exchangeRateList,
        }),
        freightPaymentType: watch("freightPaymentType"),
        liner: watch("liner"),
        isTransit: watch("isTransit"),
        exchangeRate: findExchangeRate(exchangeRateList, "USD"),
        totalPrice: getAllTotalItemPriceOfFeeData(
          watch("freightFeeData"),
          watch("domesticFeeData"),
          watch("localFeeData"),
          watch("inlandFeeData"),
          watch("otherFeeData"),
          watch("taxFeeData")
        ),
        freightFee: watch("freightFeeData"),
        localFee: watch("localFeeData"),
        domesticFee: watch("domesticFeeData"),
        inlandFee: watch("inlandFeeData"),
        taxFee: watch("taxFeeData"),
        otherFee: watch("otherFeeData"),
        comment: watch("comment"),
        expiredAt: watch("expiredAt"),
        leadtime: watch("leadtime"),
        linerId: getLinerId(linerList, watch("liner")),
      },
      bidId: bidDetail.id,
      quotationId: quotationWithIsTaxExempt.id,
      anotherPortInlandOptionComment: watch("anotherPortInlandOptionComment"),
    };

    if (bidDetail.isImport) {
      confirmBidQuote(requestPayload, {
        onSuccess: () => {
          // 견적 지원 시 의뢰 상세로 돌아가면서 견적 확정 탭으로 이동하기 위해 탭 키 변경
          setTabDefaultActiveKey(
            bidDetail.serviceType === "consolidation" ? "2" : "3"
          );
          history.replace(`/bid/detail/${bidDetail.id}`);
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      });
      return;
    }

    confirmExportBidQuote(requestPayload, {
      onSuccess: () => {
        // 견적 지원 시 의뢰 상세로 돌아가면서 견적 확정 탭으로 이동하기 위해 탭 키 변경
        setTabDefaultActiveKey(
          bidDetail.serviceType === "consolidation" ? "2" : "3"
        );
        history.replace(`/bid/detail/${bidDetail.id}`);
      },

      onError: () => {
        handleSnackbarOpen("요청에 실패했습니다.", "error");
      },
    });

    return;
  }, [
    bidDetail.id,
    bidDetail.isImport,
    bidDetail.serviceType,
    confirmBidQuote,
    confirmExportBidQuote,
    exchangeRateList,
    handleSnackbarOpen,
    history,
    linerList,
    quotationWithIsTaxExempt.id,
    setTabDefaultActiveKey,
    watch,
  ]);

  return (
    <BidQuotation
      bidDetail={bidDetail}
      exchangeRateList={exchangeRateList}
      control={control}
      watch={watch}
      reset={reset}
      setValue={setValue}
      handleSubmit={handleSubmit}
      errors={errors}
      submitFunction={handleQuotationConfirm}
      applyItems={applyItems}
      linerList={linerList}
      quotationType={"confirm"}
      isLoading={bidDetail.isImport ? isLoading : isExportLoading}
    />
  );
}

export default ConfirmQuotation;
