import { ChangeEvent, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import { CHECK_IS_OCEANTICKET_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { BooleanStringV2 } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateCheckPoints,
  BidCreateFormData,
  CfsReceiving,
  InlandTransportType,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import RadioGroupWithReactHookForm, {
  RadioGroupWithReactHookFormOption,
} from "@sellernote/_shared-for-forwarding-admin/src/components/RadioGroupWithReactHookForm";

const isContainRadioGroupOptions: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "포함",
      value: "TRUE",
    },
    {
      label: "미포함",
      value: "FALSE",
    },
  ];

const containerAccessableRadioGroupOptions: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "가능",
      value: "TRUE",
    },
    {
      label: "불가능",
      value: "FALSE",
    },
  ];

const cfsReceivingRadioGroupOptions: RadioGroupWithReactHookFormOption<CfsReceiving>[] =
  [
    {
      label: "직접(고객사)",
      value: "direct",
    },
    {
      label: "대행(쉽다)",
      value: "shipda",
    },
  ];

const containerStuffingRadioGroupOptions: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "직접(고객사)",
      value: "TRUE",
    },
    {
      label: "대행(쉽다)",
      value: "FALSE",
    },
  ];

const containerDevanningRadioGroupOptions: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "신청",
      value: "TRUE",
    },
    {
      label: "미신청",
      value: "FALSE",
    },
  ];

const inlandTransportTypeRadioGroupOptions: RadioGroupWithReactHookFormOption<InlandTransportType>[] =
  [
    {
      label: "독차",
      value: "sole",
    },
    {
      label: "합차",
      value: "consol",
    },
    {
      label: "해당 없음",
      value: "none",
    },
  ];

export default function useDefaultCheckPoints({
  isOceanticket,
}: {
  isOceanticket: CHECK_IS_OCEANTICKET_RES | undefined;
}) {
  const { control, setValue, watch } = useFormContext<BidCreateFormData>();

  const endType = watch("endType");

  const freightType = watch("freightType");

  const bidType = watch("bidType");

  const incoterms = watch("incoterms");

  const [prevFreightType, setPrevFreightType] = useState(freightType);
  const [prevBidType, setPrevBidType] = useState(bidType);
  const [prevIncoterms, setPrevIncoterms] = useState(incoterms);

  const checkPointsRef = useCallback(
    (node) => {
      if (node !== null) {
        if (
          freightType !== prevFreightType ||
          bidType !== prevBidType ||
          incoterms !== prevIncoterms
        ) {
          setPrevFreightType(freightType);
          setPrevBidType(bidType);
          setPrevIncoterms(incoterms);

          const hasVisitedQuotationPage = sessionStorage.getItem(
            "hasVisitedQuotationPage"
          );

          /** 견적서 작성 페이지에서 돌아오는 경우 첫 렌더링에서 체크포인트를 자동셋팅하지 않기 위해 추가   */
          if (hasVisitedQuotationPage === "TRUE") {
            /** 값이 계속 남아있으면 폼 입력에 따른 체크포인트 셋팅이 되지 않기 때문에 제거 */
            sessionStorage.removeItem("hasVisitedQuotationPage");
            return;
          }

          /** 수출 의뢰 체크포인트 값 셋팅 */
          if (bidType === "export") {
            if (incoterms === "CIF" || incoterms === "CFR") {
              freightType === "FCL" && setValue("containerStuffing", "FALSE");
              setValue("cfsReceiving", "shipda");
              setValue("inlandTransportType", "sole");
              return;
            }

            if (incoterms === "CIP" || incoterms === "CPT") {
              setValue("cfsReceiving", "direct");
              setValue("inlandTransportType", "none");
              return;
            }

            if (
              freightType === "FCL" &&
              (incoterms === "DDP" || incoterms === "DAP")
            ) {
              setValue("containerStuffing", "FALSE");
              setValue("cfsReceiving", "shipda");
              setValue("inlandTransportType", "sole");
              setValue("containerDevanning", "FALSE");

              return;
            }

            setValue("cfsReceiving", "shipda");
            setValue("inlandTransportType", "sole");

            return;
          }

          /** 수입 의뢰 체크포인트 값 셋팅 */
          if (freightType === "FCL") {
            setValue("containLss", "FALSE");
            setValue("containOceanSurcharge", "TRUE");
            setValue("containDomesticFee", "TRUE");
            setValue("containerAccessable", "TRUE");
            return;
          }

          if (freightType === "LCL") {
            setValue("containLss", "FALSE");
            setValue("containOceanSurcharge", "TRUE");
            setValue("containDomesticFee", "TRUE");
            setValue("containsWarehouseFee", "TRUE");

            return;
          }

          if (freightType === "AIR") {
            setValue("containDomesticFee", "TRUE");
            return;
          }
        }
      }
    },
    [
      bidType,
      freightType,
      incoterms,
      prevBidType,
      prevFreightType,
      prevIncoterms,
      setValue,
    ]
  );

  const getCheckPointLabel = useCallback(
    (checkPoint: BidCreateCheckPoints) => {
      switch (checkPoint) {
        case "containLss":
          return "LSS";
        case "containDomesticFee":
          return "국내부대비용";
        case "containOceanSurcharge":
          return "Ocean Surcharge";
        case "containerAccessable":
          return "도착지 컨테이너 진입여부";
        case "containsWarehouseFee":
          return "창고보관료(7일)";
        case "containerStuffing":
          return "컨테이너 적입";
        case "containerDevanning":
          return "컨테이너 적출";
        case "cfsReceiving":
          return freightType === "AIR" ? "터미널 입고" : "CFS 입고";
        case "inlandTransportType":
          return "운송방식";
        default:
          return "";
      }
    },
    [freightType]
  );

  const getCheckPointRadioGroupOption = useCallback(
    (checkPoint: BidCreateCheckPoints) => {
      const cfsReceiving = watch("cfsReceiving");

      switch (checkPoint) {
        case "containerAccessable":
          return containerAccessableRadioGroupOptions;
        case "cfsReceiving":
          return cfsReceivingRadioGroupOptions;
        /** AIR 터미널 입고 에서 대행(쉽다)일 때는 운송방식의 해당없음이 없어야 함 */
        case "inlandTransportType":
          return inlandTransportTypeRadioGroupOptions.filter((option) => {
            if (cfsReceiving === "shipda") {
              return option.value !== "none";
            }
            return option;
          });
        case "containerStuffing":
          return containerStuffingRadioGroupOptions;
        case "containerDevanning":
          return containerDevanningRadioGroupOptions;
        default:
          return isContainRadioGroupOptions;
      }
    },
    [watch]
  );

  /**
   * 컨테이너 적입여부 라디오버튼 변경 시 이팩트 함수
   */
  const handleEffectContainerStuffingOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === "TRUE") {
        setValue("cfsReceiving", "direct");
        setValue("inlandTransportType", "none");
        return;
      }
      setValue("cfsReceiving", "shipda");
      setValue("inlandTransportType", "sole");
      return;
    },
    [setValue]
  );

  /**
   * CFS 입고 라디오버튼 변경 시 이팩트 함수
   */
  const handleEffectCfsReceivingOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === "direct") {
        setValue("inlandTransportType", "none");
        return;
      }
      setValue("inlandTransportType", "sole");
      return;
    },

    [setValue]
  );

  const getCheckPoint = useCallback(
    ({
      checkPoint,
      disabled,
      handleEffectOnChange,
    }: {
      checkPoint: BidCreateCheckPoints;
      disabled?: boolean;
      handleEffectOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    }) => {
      return (
        <Grid item container spacing={3} alignItems="center">
          <Grid item xs={2}>
            <Typography variant="subtitle2" component="span">
              {getCheckPointLabel(checkPoint)}
            </Typography>
          </Grid>

          <Grid item>
            <RadioGroupWithReactHookForm
              name={checkPoint}
              control={control}
              radioGroupOption={getCheckPointRadioGroupOption(checkPoint)}
              disabled={disabled}
              handleEffectOnChange={handleEffectOnChange}
            />
          </Grid>
        </Grid>
      );
    },
    [control, getCheckPointLabel, getCheckPointRadioGroupOption]
  );

  const FclCheckPoints = useCallback(() => {
    return (
      <Grid item container direction={"column"} xs={11}>
        {getCheckPoint({ checkPoint: "containLss" })}

        {getCheckPoint({
          checkPoint: "containOceanSurcharge",
        })}

        {getCheckPoint({
          checkPoint: "containDomesticFee",
        })}

        {endType === "inland"
          ? getCheckPoint({
              checkPoint: "containerAccessable",
            })
          : undefined}
      </Grid>
    );
  }, [endType, getCheckPoint]);

  const LclCheckPoints = useCallback(() => {
    return (
      <Grid item container direction={"column"} xs={11}>
        {getCheckPoint({ checkPoint: "containLss" })}

        {getCheckPoint({
          checkPoint: "containOceanSurcharge",
        })}

        {getCheckPoint({
          checkPoint: "containDomesticFee",
        })}

        {isOceanticket
          ? getCheckPoint({
              checkPoint: "containsWarehouseFee",
            })
          : undefined}
      </Grid>
    );
  }, [getCheckPoint, isOceanticket]);

  const AirCheckPoints = useCallback(() => {
    return (
      <Grid item container direction={"column"} xs={11}>
        {getCheckPoint({
          checkPoint: "containDomesticFee",
        })}
      </Grid>
    );
  }, [getCheckPoint]);

  const exportCheckPoints = useCallback(() => {
    const containerStuffing = watch("containerStuffing");
    const cfsReceiving = watch("cfsReceiving");

    if (incoterms === "CIF" || incoterms === "CFR")
      return (
        <Grid item container direction={"column"} xs={11}>
          {freightType === "FCL" &&
            getCheckPoint({
              checkPoint: "containerStuffing",
              handleEffectOnChange: handleEffectContainerStuffingOnChange,
            })}

          {getCheckPoint({
            checkPoint: "cfsReceiving",
            disabled: freightType === "FCL" && containerStuffing === "FALSE",
            handleEffectOnChange: (event) => {
              handleEffectCfsReceivingOnChange(event);
            },
          })}

          {getCheckPoint({
            checkPoint: "inlandTransportType",
            disabled:
              (cfsReceiving === "direct" && containerStuffing === "TRUE") ||
              (freightType !== "FCL" && cfsReceiving === "direct"),
          })}
        </Grid>
      );

    if (incoterms === "CIP" || incoterms === "CPT") {
      return (
        <Grid item container direction={"column"} xs={11}>
          {getCheckPoint({
            checkPoint: "cfsReceiving",
            // AIR의 CFS 입고 변경 이펙트 경우에는 따로 관리
            handleEffectOnChange: () => {
              if (containerStuffing === "FALSE") {
                if (cfsReceiving === "shipda") {
                  setValue("inlandTransportType", "none");
                  return;
                }
                setValue("inlandTransportType", "sole");
              }
            },
          })}

          {getCheckPoint({
            checkPoint: "inlandTransportType",
            disabled: cfsReceiving === "direct",
          })}
        </Grid>
      );
    }

    if (freightType === "FCL" && (incoterms === "DDP" || incoterms === "DAP")) {
      return (
        <Grid item container direction={"column"} xs={11}>
          {freightType === "FCL" &&
            getCheckPoint({
              checkPoint: "containerStuffing",
              handleEffectOnChange: handleEffectContainerStuffingOnChange,
            })}

          {getCheckPoint({
            checkPoint: "cfsReceiving",
            disabled:
              (freightType === "FCL" && containerStuffing === "FALSE") ||
              (freightType !== "FCL" &&
                (incoterms === "DAP" || incoterms === "DDP")),
            handleEffectOnChange: (event) => {
              handleEffectCfsReceivingOnChange(event);
            },
          })}

          {getCheckPoint({
            checkPoint: "inlandTransportType",
            disabled: cfsReceiving === "direct" && containerStuffing === "TRUE",
          })}

          {freightType === "FCL" &&
            getCheckPoint({
              checkPoint: "containerDevanning",
            })}
        </Grid>
      );
    }

    return (
      <Grid item container direction={"column"} xs={11}>
        {getCheckPoint({
          checkPoint: "cfsReceiving",
          disabled: cfsReceiving === "shipda",
          handleEffectOnChange: (event) => {
            handleEffectCfsReceivingOnChange(event);
          },
        })}

        {getCheckPoint({
          checkPoint: "inlandTransportType",
          disabled: cfsReceiving === "direct",
        })}
      </Grid>
    );
  }, [
    freightType,
    getCheckPoint,
    handleEffectCfsReceivingOnChange,
    handleEffectContainerStuffingOnChange,
    incoterms,
    setValue,
    watch,
  ]);

  const getCheckPointsByFreightType = useCallback(() => {
    if (bidType === "export") {
      return exportCheckPoints();
    }
    if (freightType === "FCL") {
      return FclCheckPoints();
    }

    if (freightType === "LCL") {
      return LclCheckPoints();
    }

    if (freightType === "AIR") {
      return AirCheckPoints();
    }
  }, [
    AirCheckPoints,
    FclCheckPoints,
    LclCheckPoints,
    bidType,
    exportCheckPoints,
    freightType,
  ]);

  return {
    checkPointsRef,
    prevFreightType,
    prevBidType,
    prevIncoterms,
    getCheckPointsByFreightType,
  };
}
